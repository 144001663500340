import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
//url: string = 'localhost';
//port: string = ':49160';
//protocol: string = 'http://';
url: string = 'printshop-app.be';
port: string = ':8443';
protocol: string = 'https://';
colorPrice: number = 0.35;
blackPrice: number = 0.025; 
  
}