import { Order } from './order.entity';

export class Cart {
    
    orders: Order[];

    name: string;
    firstName: string;
    phone: string;
    email: string;
    street: string;
    city: string;
    postCode: string;
    
    shippingName: string;
    shippingFirstName: string;
    shippingStreet: string;
    shippingCity: string;
    shippingPostCode: string;
    
    mail: string;
    shipping:boolean;
    contactAsShipping:boolean;
    
    constructor() {
        this.orders = [];
        this.mail = '';
        this.shipping = true;
        this.contactAsShipping = false;
    }
    
    setMail(){
        this.mail = this.getCartDesc();
    }
    
    addOrder(order : Order){
        this.orders.push(order); 
    }
    
    removeOrder(id: string){
        var newOrders: Order[] = [];

        for (var i = 0; i < this.orders.length; i++) {
            if (this.orders[i].id !== id) {
                newOrders.push(this.orders[i]);
            }
        }
        this.orders = newOrders;
    }
    
    getTotal(): number{
        var total:number = 0;
        for (var i = 0; i < this.orders.length; i++) {
            total = total + this.orders[i].getTotal();
            
            
        }
            return Number((total*1).toFixed(2));
    }
    
    getTotalWithShipping(): number{
        var total:number = 0;
        if(this.getTotal()<2)
            total = total + this.getTotal() + this.getShippingCost() +1.5;
            //used for test transaction (limit cost to one page)
            //total = total + this.getTotal() + this.getShippingCost();
        else
            total = total + this.getTotal() + this.getShippingCost();
        return total;
    }
    
    getShippingCost(): number{
        if(!this.shipping)
            return 0;
        var nbPages:number = 0;
        var orderPages:number = 0;
        for (var i = 0; i < this.orders.length; i++) {
            orderPages = this.orders[i].nbBlackPages + this.orders[i].nbColorPages + this.orders[i].nbErrorPages;
            if(this.orders[i].rectoVerso)
                orderPages = orderPages/2;
            nbPages = nbPages + orderPages;
        }
        if(nbPages <= 15)
            return 2.6;
        if(nbPages > 15 && nbPages <60)
            return 3.7;
        else
            return 6;
    }
    
    
    findAll(): Order[] {
        return this.orders;
    }

    find(id: string): Order {
        return this.orders[this.getSelectedIndex(id)];
    }

    private getSelectedIndex(id: string) {
        for (var i = 0; i < this.orders.length; i++) {
            if (this.orders[i].id == id) {
                    return i;
            }
        }
        return -1;
    }
    
    getCartDesc() : string{
        var description = "Détail de la commande \n \n";
        description = description + "\n\nInformation de contact: \n  " + this.name +" "+ this.firstName + "\n  "+ this.street + "\n  "+ this.postCode + "  "+ this.city  + "\n  "+ this.phone + "\n  "+ this.email;
        if(this.shipping && this.contactAsShipping)
            description = description + "\n\nAdresse de livraison: \n  " + this.name +" "+ this.firstName + "\n  "+ this.street + "\n  "+ this.postCode + "  "+ this.city;
        if(this.shipping && !this.contactAsShipping)
            description = description + "\n\nAdresse de livraison: \n  " + this.shippingName +" "+ this.shippingFirstName + "\n  "+ this.shippingStreet+ "\n  "+ this.shippingPostCode+ "  "+ this.shippingCity;
        for (var i = 0; i < this.orders.length; i++) {
                description = description + "\n\nFichier no "+(i+1) + "\n\n" + this.orders[i].getOrderDesc();
        }
        description = description + "\n \nMontant impression: "+ this.getTotal() + "€";
        description = description + "\n \nFrais de livraison: "+ this.getShippingCost() + "€";
        if(this.getTotal()<2)
            description = description + "\n \nFrais d'ouverture de fichier' "+ "1.5 €";
        
        
        return description;
    }

    
}