
    <div *ngIf="showLoader" style="position:relative; text-align: center;">
      <div #loaderRef><ng-content select=".ngxLoader" *ngIf="!hideLoader"></ng-content></div>
      <img *ngIf="loaderRef.children.length === 0 && !hideLoader" src="{{loaderImage}}" />
    </div>
    <div class="error" *ngIf="errorMessage">Error</div>
    <div class="errorInfoDiv" *ngIf="errorMessage">{{errorMessage}}</div>
    <div *ngIf="showDropinUI && clientToken" ngxBraintreeDirective>
      <div id="dropin-container"></div>

      <div #buttonsRef><ng-content select=".ngxButtons"></ng-content></div>

      <!-- No buttons are projected and no styles sent, so the default button (Purchase) with default styles will be used. -->
      <div *ngIf="(buttonsRef.children.length === 0 && !enabledStyle && !disabledStyle)">
          <button [disabled]="!enablePayButton" *ngIf="showPayButton" (click)="pay()">
            {{buttonText}}
          </button>
      </div>

      <!-- No buttons are projected but styles are sent for the default button (Purchase). So, the sent styles will be used. -->
      <div *ngIf="(buttonsRef.children.length === 0 && enabledStyle && disabledStyle)">
          <button [disabled]="!enablePayButton" [ngStyle]="enablePayButton ? enabledStyle : disabledStyle" *ngIf="showPayButton" (click)="pay()">
            {{buttonText}}
          </button>
      </div>

      <!-- Buttons are projected with no styles. -->
      <!-- This is being handled via the directive. No styles will be applied as the user hasn't sent any styles. Default styles will not be applied. -->

      <!-- Buttons are projected with styles -->
      <!-- This is being handled via the directive. -->


    </div>
    <div *ngIf="clientTokenNotReceived">
      <div class="error">Error! Client token not received.</div>
      <div class="errorInfoDiv">Make sure your clientTokenURL's JSON response is as shown below:
        <pre>{{ '{' }}"token":"braintree_client_token_generated_on_your_server"{{'}'}}</pre>
      </div>
    </div>