import { Directive, OnInit, OnDestroy, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[ngxBraintreeDirective]'
})
export class NgxBraintreeDirective implements OnInit, OnDestroy {

  script: any;
  scriptData: any;

  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: any) { }

  ngOnInit() {
        //data collector for 3D secure  

      
    this.script = this.renderer.createElement('script');
    this.script.type = 'text/javascript';
    this.script.src = 'https://js.braintreegateway.com/web/dropin/1.25.0/js/dropin.min.js';     
    this.renderer.appendChild(this.document.body, this.script);
      
      
    //data collector for 3D secure  
    //this.scriptData = this.renderer.createElement('script');
    //this.scriptData.type = 'text/javascript';
    //this.scriptData.src = 'https://js.braintreegateway.com/web/3.68.0/js/data-collector.min.js';     
    //this.renderer.appendChild(this.document.body, this.scriptData);
      
      
  }

  ngOnDestroy() {
    this.renderer.removeChild(this.document.body, this.script);
  }

}
