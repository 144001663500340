import { Component, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import { MatDialogRef} from '@angular/material';
import { UploadService } from '../upload.service';
import {Globals} from '../../globals'
import { forkJoin, timer, from } from 'rxjs';
import { map, concatMap, filter, take } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Color} from '../../entities/color.entity';
import { Finition} from '../../entities/finition.entity';
import { Couverture} from '../../entities/couverture.entity';
import { Order} from '../../entities/order.entity';
import { Cart} from '../../entities/cart.entity';
import { ColorService} from '../../services/color.service';
import { FinitionService} from '../../services/finition.service';
import { CouvertureService} from '../../services/couverture.service';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';



import {
MatButtonModule,
MatFormFieldModule,
MatInputModule,
MatRippleModule
} from '@angular/material';

export interface Item {
    value: string;
    viewValue: string;
} 

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
}
}



@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
styleUrls: ['./dialog.component.scss']
    })
export class DialogComponent implements OnInit {
@ViewChild('file', { static: false }) file;


private couvertures: Couverture[];
private couleurs: Color[];
private finitions: Finition[];
private order: Order;
private cart: Cart;


public files: Set<File> = new Set();

constructor(public dialogRef: MatDialogRef<DialogComponent>, public uploadService: UploadService, public httpClient: HttpClient, public globals: Globals, private colorService: ColorService, private finitionService: FinitionService, private couvertureService: CouvertureService, private cdRef: ChangeDetectorRef) { 
}

emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
]);

matcher = new MyErrorStateMatcher();

ngOnInit() {
    this.couvertures = this.couvertureService.findAll();
    this.finitions = this.finitionService.findAll();
    this.couleurs = this.colorService.findAll();
    this.order = new Order(this.globals, this.finitionService, this.couvertureService, this.colorService);
    this.cart = new Cart();
    this.cdRef.detectChanges();
    this.reload();

}


progress;
//canBeClosed = true;
primaryButtonText = '';
step = "upload";
fileStatus;
showCancelButton = true;
uploading = false;
uploadSuccessful = false;
fileAdded = false;
fileUploaded = false;
treatment = false;
uploadResult;
treated = false;
uuid;
urlTimerReq;
fileInfoTimerReq;
fileInfo = {};
fileInfoTreated;
urlTreated;
paymentSucceed = false;
paymentError = false;
transactionID;
clientTokenUrl = this.globals.protocol+this.globals.url+this.globals.port+'/client_token/';
createPurchaseURL='';
createPurchaseURLTemplate=this.globals.protocol+this.globals.url+this.globals.port+'/checkout/';
_reload = true;
txId = '';

private reload() {
    setTimeout(() => this._reload = false);
    setTimeout(() => this._reload = true);
}



resetOnClick(event){
    event.target.value='';
}


reset() {
//BUG IF add before previous not loaded
        if(this.urlTimerReq)
            this.urlTimerReq.unsubscribe(); 
        if(this.fileInfoTimerReq)
            this.fileInfoTimerReq.unsubscribe(); 
    this.order = new Order(this.globals, this.finitionService, this.couvertureService, this.colorService);
    this.progress = undefined;
    //this.canBeClosed = true;
    this.primaryButtonText = '';
    this.step = "upload";
    this.fileStatus = undefined;
    this.showCancelButton = true;
    this.uploading = false;
    this.uploadSuccessful = false;
    this.fileAdded = false;
    this.fileUploaded = false;
    this.treatment = false;
    this.uploadResult = undefined;
    this.treated = false;
    this.uuid = undefined;
    this.urlTimerReq = undefined;
    this.fileInfoTimerReq = undefined;
    this.fileInfo = undefined; 
    this.fileInfoTreated = false;
    this.urlTreated = false;
    this.paymentSucceed = false;
    this.paymentError = false;
    this.transactionID = undefined;
    this.txId = '';
    this.files = new Set();
}

resetAll() {
    this.cart = new Cart();
    this.reset();
}



onFilesAdded() {

    this.reset();

    const files: { [key: string]: File } = this.file.nativeElement.files;

for (let key in files) {
if (!isNaN(parseInt(key))) {    
    this.files.clear();
    this.files.add(files[key]);
    this.primaryButtonText = 'Envoyer';
    this.fileStatus = Array.from(this.files)[0].name;
//this.files[0]=files[key];
}
}

    this.fileAdded = true;
    this.cdRef.detectChanges();
    this.reload();
}

addFiles() {

    this.file.nativeElement.click();
    this.reload();


}

pollUrl(uuid) {

this.urlTimerReq = timer(0, 1000).subscribe(() => this.httpClient.get(this.globals.protocol+this.globals.url+this.globals.port+'/fileurl/'+uuid).subscribe((res)=>{
if(res['url'] !== ''){
    this.urlTimerReq.unsubscribe(); 
    this.fileStatus = Array.from(this.files)[0].name; 
this.order.file = Array.from(this.files)[0].name
    this.urlTreated = true;
if(this.fileInfoTreated == true){
    this.treatment = false; 
    this.treated = true; 


}
    this.order.fileUrl = res['url'];

}
    }))
}

backHome(){

    this.step = 'upload';
    this.order.getOrderDesc();
}

backToInfo(){
    if(this.cart.shipping && !this.cart.contactAsShipping)
      this.step = 'shippingAdress';
    else
      this.step = 'information'; 
    this.paymentSucceed = false;
    this.paymentError = false;
    this.transactionID = undefined;
    this.txId = '';

}

stepAfterContactForm(){
    if(this.cart.shipping && !this.cart.contactAsShipping)
        this.step = 'shippingAdress';
    else
        this.goToPayment();
}

goToPayment(){

let headers = new HttpHeaders({
    'Content-Type': 'application/json'
    });
let options = {
    headers: headers
}
    //this.order.setMessage();
    this.cart.setMail();
this.httpClient.post(this.globals.protocol+this.globals.url+this.globals.port+'/txinfo', this.cart, options).subscribe(res => {
//this.txId = '';
    this.txId = res['txId'];
if(this.txId && this.txId.length >0){
    this.step = 'payment';
    this.createPurchaseURL = this.createPurchaseURLTemplate +this.txId;
}
    },
err => {
    console.log(err);
    });
}


addToCart() {
    this.cart.addOrder(this.order);
    console.log(this.cart);
    console.log(this.cart.getCartDesc());
    this.reset();
    
}

goToCart(){
    if(this.uploadSuccessful && this.order.quantity > 0 && this.order.getTotal() > 0 && (this.order.nbBlackPages >0 || this.order.nbColorPages >0 )){
        this.cart.addOrder(this.order);
    }
    this.reset();
    this.step = 'cart';
}

deleteItem(id){
    this.cart.removeOrder(id);
}

backHomeAndReset(){
    this.resetAll();
    this.step = 'upload';
    this.paymentSucceed = false;
    this.paymentError = false;
    this.transactionID = undefined;
}

pollFileINfo(uuid) {

this.fileInfoTimerReq = timer(0, 1000).subscribe(() => this.httpClient.get(this.globals.protocol+this.globals.url+this.globals.port+'/fileinfo/'+uuid).subscribe((res)=>{
if(res['fileInfo'] !== ''){
    this.fileInfoTimerReq.unsubscribe(); 
    this.fileInfoTreated = true;
if(this.urlTreated == true){
    this.treatment = false; 
    this.treated = true; 
}
    this.fileInfo = res['fileInfo'];
    this.order.nbBlackPages = Number(res['fileInfo'].blackPages);
    this.order.nbColorPages = Number(res['fileInfo'].colorPages);
    this.order.nbErrorPages = Number(res['fileInfo'].errorPages);

}
    }))
}

onPaymentStatus(event){
        if(event.success === true)
            {this.paymentSucceed = true;
            this.paymentError = false;
        this.transactionID = event.transaction.id}
else {
    this.paymentSucceed = false;
    this.paymentError = true;
}
}

closeDialog() {
// if everything was uploaded already, just close the dialog
/*if (this.uploadSuccessful) {
    this.primaryButtonText = '';
        return this.dialogRef.close();
}*/

// set the component state to "uploading"
    this.uploading = true;
    this.fileStatus = "Téléchargement";

// start the upload and save the progress map
    this.progress = this.uploadService.upload(this.files);
    for (const key in this.progress) {
        this.progress[key].progress.subscribe(val => console.log(val));
    }

// convert the progress map into an array
    let allProgressObservables = [];
for (let key in this.progress) {
    allProgressObservables.push(this.progress[key].progress);
}

// Adjust the state variables

// The OK-button should have the text "Finish" now
    this.primaryButtonText = '';

// The dialog should not be closed while uploading
    //this.canBeClosed = false;
   // this.dialogRef.disableClose = true;

// Hide the cancel-button
    this.showCancelButton = false;

// When all progress-observables are completed...
forkJoin(allProgressObservables).subscribe(end => {
// ... the dialog can be closed again...
    //this.canBeClosed = true;
    //this.dialogRef.disableClose = false;

// ... the upload was successful...
    this.uploadSuccessful = true;

// ... and the component is no longer uploading
    this.uploading = false;
    this.fileUploaded = true;


    this.fileStatus = "Traitement";
    this.treatment = true;
    this.pollUrl(this.uploadService.getResult().uuid);
    this.pollFileINfo(this.uploadService.getResult().uuid);
    this.reload();

    });
}







}
