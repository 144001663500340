import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadComponent } from './upload.component';
import { MatButtonModule, MatDialogModule, MatListModule, MatProgressBarModule, MatFormFieldModule, MatInputModule, MatRadioModule, MatSelectModule, MatGridListModule } from '@angular/material';
import { DialogComponent } from './dialog/dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UploadService } from './upload.service';
import { HttpClientModule } from '@angular/common/http';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { ColorService} from '../services/color.service';
import { FinitionService} from '../services/finition.service';
import { CouvertureService} from '../services/couverture.service';
//from npm (offical)
//import { NgxBraintreeModule } from 'ngx-braintree'; 
//local copy forked
//import { NgxBraintreeComponent } from '../ngx-braintree/ngx-braintree.component'; 
//import { NgxBraintreeService } from '../ngx-braintree/ngx-braintree.service'; 
import { NgxBraintreeModule } from '../ngx-braintree/ngx-braintree.module'; 


import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpModule }    from '@angular/http';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatListModule, FlexLayoutModule, HttpClientModule, BrowserAnimationsModule, MatProgressBarModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatCardModule, MatRadioModule, MatSelectModule, MatGridListModule, FormsModule, MatIconModule, ReactiveFormsModule, HttpModule, NgxBraintreeModule],
  declarations: [UploadComponent, DialogComponent],
  exports: [UploadComponent],
  entryComponents: [DialogComponent], // Add the DialogComponent as entry component
  providers: [UploadService, ColorService, FinitionService, CouvertureService]
})
export class UploadModule {}
