import { Injectable } from '@angular/core';

import { Finition } from '../entities/finition.entity';

@Injectable()
export class FinitionService {

    private finitions: Finition[];

    constructor() {
        this.finitions = [
            {id: 'aucun', name: 'Pas de finition', price: 0},
            {id: 'sp-pl-cou-pl', name: 'Reliure spirale plastique avec couverture plastique', price: 2.5},
            {id: 'sp-pl', name: 'Reliure spirale plastique sans couverture plastique', price: 2.5},
            {id: 'sp-ml-cou-pl', name: 'Reliure spirale métalique avec couverture plastique', price: 3.8},
            {id: 'sp-ml', name: 'Reliure spirale métalique sans couverture plastique', price: 3.8},
            {id: 'colle-cou-pl', name: 'Reliure colle avec couverture plastique', price: 3.5},
            {id: 'colle', name: 'Reliure colle sans couverture plastique', price: 3.8},
            {id: 'agr-1', name: 'Agrafage 1 point métal', price: 0.04},
            {id: 'agr-2', name: 'Agrafage 2 point métal', price: 0.04},
            {id: 'perf', name: 'Perforation 2 trous', price: 0.04}
        ];
    }

    findAll(): Finition[] {
        return this.finitions;
    }

    find(id: string): Finition {
        return this.finitions[this.getSelectedIndex(id)];
    }

    private getSelectedIndex(id: string) {
        for (var i = 0; i < this.finitions.length; i++) {
            if (this.finitions[i].id == id) {
                return i;
            }
        }
        return -1;
    }

}