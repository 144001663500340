import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';

import { AppComponent } from './app.component';
import { UploadModule } from './upload/upload.module';

import { Globals } from './globals';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    UploadModule
  ],
  providers: [ Globals ],
  bootstrap: [AppComponent]
})



export class AppModule { }
