import { Injectable } from '@angular/core';

import { Color } from '../entities/color.entity';

    @Injectable()
export class ColorService {

    private colors: Color[];

    constructor() {
        this.colors = [
            {id: 'blanc', name: 'Blanc'},
            {id: 'rouge', name: 'Rouge'},
            {id: 'orange', name: 'Orange'},
            {id: 'violet', name: 'Violet'},
            {id: 'turquoise', name: 'Bleu turquoise'},
            {id: 'canari', name: 'Jaune Canari'},
            {id: 'soleil', name: 'Jaune Soleil'},
            {id: 'fushia', name: 'Rose Fushia'},
            {id: 'rose', name: 'Rose'},
            {id: 'saumon', name: 'Saumon'},
            {id: 'sapin', name: 'Vert Sapin'},
            {id: 'menthe', name: 'Vert Menthe'},
            {id: 'vert-clair', name: 'Vert clair'},
            {id: 'ivoire', name: 'Ivoire'}
            ];
    }

    findAll(): Color[] {
        return this.colors;
    }

    find(id: string): Color {
        return this.colors[this.getSelectedIndex(id)];
    }

    private getSelectedIndex(id: string) {
        for (var i = 0; i < this.colors.length; i++) {
            if (this.colors[i].id == id) {
                    return i;
            }
        }
        return -1;
    }

}