import { Finition } from '../entities/finition.entity';
import { Couverture } from '../entities/couverture.entity';
import { Color } from '../entities/color.entity';
import {Globals} from '../globals';
import { CouvertureService } from '../services/couverture.service';
import { FinitionService } from '../services/finition.service';
import { ColorService } from '../services/color.service';

export class Order {

    id: string;
    file: string;
    fileUrl: string;
    quantity:  number;
    rectoVerso: boolean;
    color: boolean;
    couverture: Couverture;
    couvertureColor: Color;
    remarque: string;
    finition: Finition;
    nbBlackPages: number;
    nbColorPages: number;
    nbErrorPages : number;

    constructor(public globals: Globals, public finitionService: FinitionService, public couvertureService: CouvertureService, public colorService: ColorService) {
        this.id = this.uuidv4();
        this.rectoVerso = false;
        this.color = false;
        this.quantity = 1;
        this.nbBlackPages = 0;
        this.nbColorPages = 0;
        this.nbErrorPages = 0;
        this.couverture = couvertureService.find('aucun');
        this.finition = finitionService.find('aucun');
    }
    
    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    
    getColorId(): string{
            if(this.couvertureColor)
                return this.couvertureColor.id;
        else
            return undefined;
    
    }

    setCouverture(id: string){
        this.couverture = this.couvertureService.find(id);
    }
    
    setFinition(id: string){
        this.finition = this.finitionService.find(id);
    }

    setCouvertureColor(id: string){
        this.couvertureColor = this.colorService.find(id);
    }
    


    getTotal(): number{
        var price:number = this.quantity * ((this.nbBlackPages * this.globals.blackPrice) + (this.nbErrorPages * this.globals.blackPrice)) ;
        if(this.color){
            price = price + (this.quantity * this.nbColorPages * this.globals.colorPrice) ;
        }
        else{
            price = price + (this.quantity * this.nbColorPages * this.globals.blackPrice) ;
        }
            price = price + (this.quantity * this.couverture.price) + (this.quantity * this.finition.price);
            return Number((price*1).toFixed(2));
    }
    
    getOrderDesc(): string{
        var descritption = 
        ' Nom du fichier: $file$\n Url du fichier: $fileUrl$\n Quantité: $quantity$\n Recto/Verso: $rectoVerso$\n Couleur: $color$\n Couverture: $couverture$\n Couleur de couverture: $couvertureColor$\n Finition: $finition$\n Remarque: $remarque$\n\n Feuilles en N/B: $nbBlackPages$\n Feuilles en couleurs : $nbColorPages$\n\n Prix: $prix$€\n\n';

        var rectoVerso: string;
        if(this.rectoVerso)
            rectoVerso = 'oui';
        else
            rectoVerso = 'non';
        var color: string;
        if(this.color)
            color = 'oui';
        else
            color='non';
        var couvertureColor: string;
        if(!this.couvertureColor)
            couvertureColor = '';
        else
            couvertureColor=this.couvertureColor.name;

        var nbBlack:number = this.nbBlackPages + this.nbErrorPages;
        
        var prix:number = this.getTotal();

        var result = descritption.replace('$file$', this.file).replace('$fileUrl$', this.fileUrl).replace('$quantity$', this.quantity.toString()).replace('$rectoVerso$', rectoVerso).replace('$color$', color).replace('$couverture$', this.couverture.name).replace('$couvertureColor$', couvertureColor).replace('$finition$', this.finition.name).replace('$remarque$', this.remarque).replace('$nbBlackPages$', nbBlack.toString()).replace('$nbColorPages$', this.nbColorPages.toString()).replace('$prix$', prix.toString());

        return result

    }

}