import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogComponent } from './dialog/dialog.component';
import { UploadService } from './upload.service';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent {
  constructor(public dialog: MatDialog, public uploadService: UploadService) {let dialogRef = this.dialog.open(DialogComponent, {     width: '0px',     height: '0px', panelClass : "myDialog", disableClose: true }); }

  public openUploadDialog() {
    
  }
}
