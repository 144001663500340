import { Injectable } from '@angular/core';

import { Couverture } from '../entities/couverture.entity';

@Injectable()
export class CouvertureService {

    private couvertures: Couverture[];

    constructor() {
        this.couvertures = [
                {id: 'aucun', name: 'Pas de couverture', price: 0},
                {id: 'carton-av', name: 'Carton avant', price: 0.1},
                {id: 'carton-av-ar', name: 'Carton avant et arrière', price: 0.2}
        ];
    }

    findAll(): Couverture[] {
        return this.couvertures;
    }

    find(id: string): Couverture {
        return this.couvertures[this.getSelectedIndex(id)];
    }

    private getSelectedIndex(id: string) {
        for (var i = 0; i < this.couvertures.length; i++) {
            if (this.couvertures[i].id == id) {
                return i;
            }
        }
        return -1;
    }

}