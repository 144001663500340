<input type="file" #file style="display: none" (change)="onFilesAdded()" (click)="resetOnClick($event)" accept="application/pdf"/>


<div *ngIf="step === 'upload'" ngClass.gt-xs="transfer transfer-desktop" ngClass.lt-sm="panel panel--full panel--no-transition panel--visible"  fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="space-evenly stretch" >
    <div ngClass.gt-xs="transfer__window uploader uploader--form uploader--type-email" ngClass.lt-sm="panel__content transfer-mobile">
        <div ngClass.lt-sm="transfer" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="space-evenly stretch" >
            <div class="scrollable transfer__contents">
                <div class="scrollable__content" style="margin-right: 0px;">
                    <div class="uploader__files">
                        <form>
                            <input type="file" #file style="display: none" (change)="onFilesAdded()" >
                            <input tabindex="-1" type="file" multiple="" webkitdirectory="webkitdirectory" directory="directory">
                        </form>
                        <div class="uploader__empty-state uploader__empty-state--with-directories-selector" mat-raised-button color="primary" (click)="addFiles()">
                            <svg viewBox="24 0 72 72">
                                <path d="M60.493 72C79.883 72 96 55.882 96 36.493 96 16.118 79.882 0 60.493 0 40.118 0 24 16.118 24 36.493 24 55.883 40.118 72 60.493 72z" fill="#536dfe" fill-rule="evenodd"></path><path d="M58 34h-9c-.553 0-1 .452-1 1.01v1.98c0 .567.448 1.01 1 1.01h9v9c0 .553.452 1 1.01 1h1.98c.567 0 1.01-.448 1.01-1v-9h9c.553 0 1-.452 1-1.01v-1.98c0-.567-.448-1.01-1-1.01h-9v-9c0-.553-.452-1-1.01-1h-1.98c-.567 0-1.01.448-1.01 1v9z" fill="#ffffff" fill-rule="evenodd"></path>
                            </svg>
                            <h2>Choisissez un fichier PDF</h2>
                        </div>
                    </div>
                    <div *ngIf="fileAdded" class="file-status">
                        <div>
                            <mat-dialog-content fxFlex>
                                <mat-list>
                                    <mat-list-item *ngFor="let file of files">

                                        <h4 mat-line>{{fileStatus}}</h4>
                                        <button type="button" [hidden]="treatment || uploading || uploadSuccessful" [disabled]="!fileAdded || uploading || uploadSuccessful " [ngClass]="{'transfer__button--inactive' : !fileAdded || uploading || uploadSuccessful }" class="upload__button" (click)="closeDialog()" style="height: 80%;
    line-height: 80%;">Transférer</button>

                                        <mat-progress-bar *ngIf="uploading" mode="determinate" [value]="progress[file.name].progress | async"></mat-progress-bar>
                                        <mat-progress-bar *ngIf="treatment" mode="indeterminate" [value]="progress[file.name].progress | async"></mat-progress-bar>
                                        <i *ngIf="treated" class="material-icons md-24" style="color:green;">done</i>
                                    </mat-list-item>
                                </mat-list>
                            </mat-dialog-content> 
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div>
                        <div>
                            <div >
                                <mat-dialog-content fxFlex>
                                    <mat-list>
                                        <mat-list-item>
                                            <p mat-line >Quantité: 
                                                <mat-form-field style="width:30px">
                                                    <input [(ngModel)]="order.quantity" matInput maxlength="5"  value="1">
                                                </mat-form-field>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <mat-checkbox [(ngModel)]="order.rectoVerso" [checked]="order.rectoVerso">Recto/verso</mat-checkbox>
                                            </p>
                                        </mat-list-item>
                                        <fieldset>

                                            <legend>Couleur / NB </legend>
                                            <mat-radio-group [(ngModel)]="order.color" aria-label="Select an option">
                                                <mat-radio-button [value]="true">Couleur et N/B&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</mat-radio-button>
                                                <mat-radio-button [value]="false">N/B</mat-radio-button>
                                            </mat-radio-group>

                                        </fieldset>
                                        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
                                            &nbsp;&nbsp;&nbsp;<div class="select">
                                            <mat-form-field >
                                                <mat-label>Couverture(s)</mat-label>
                                                <mat-select [(value)]="order.getCouvertureId" (selectionChange)="order.setCouverture($event.value)">
                                                    <mat-option *ngFor="let couverture of couvertures" [value]="couverture.id">
                                                        {{couverture.name}}
                                                    </mat-option>
                                                </mat-select></mat-form-field></div>
                                            <div class="select">
                                                <mat-form-field >
                                                    <mat-label>Couleur(s)</mat-label>
                                                    <mat-select  [(value)]="order.getColorId" (selectionChange)="order.setCouvertureColor($event.value)">
                                                        <mat-option *ngFor="let couleur of couleurs" [value]="couleur.id">
                                                            {{couleur.name}}
                                                        </mat-option>
                                                    </mat-select></mat-form-field></div></div>

                                        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
                                            &nbsp;&nbsp;&nbsp;
                                            <div>
                                                <mat-form-field style="width:100%">
                                                    <mat-label>Finition(s)</mat-label>
                                                    <mat-select [(value)]="order.getFinitionId" (selectionChange)="order.setFinition($event.value)">
                                                        <mat-option *ngFor="let finition of finitions" [value]="finition.id">
                                                            {{finition.name}}
                                                        </mat-option>
                                                    </mat-select></mat-form-field></div></div>
                                        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center">
                                            &nbsp;&nbsp;&nbsp;
                                            <mat-form-field class="full-width">
                                                <input matInput #message maxlength="256" placeholder="Remarques" [(ngModel)]="order.remarque">
                                                <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
                                            </mat-form-field>
                                        </div>
                                    </mat-list>
                                </mat-dialog-content>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div>
                        <mat-divider></mat-divider>
                        <mat-grid-list *ngIf="_reload && order.nbBlackPages >=0 && order.nbColorPages >=0 && order.nbErrorPages <= 0" cols="10" rowHeight="22px" style="font-size: 13px;">
                            <mat-grid-tile [colspan] = "6"></mat-grid-tile>
                            <mat-grid-tile [colspan] = "2"><b>Quantité: </b></mat-grid-tile>
                            <mat-grid-tile [colspan] = "2"><b>Prix: </b></mat-grid-tile>
                            <mat-grid-tile [colspan] = "6">Feuilles en N/B: </mat-grid-tile>
                            <mat-grid-tile *ngIf="order.color" [colspan] = "2">{{order.quantity * order.nbBlackPages }} </mat-grid-tile>
                            <mat-grid-tile *ngIf="order.color" [colspan] = "2">{{order.quantity * order.nbBlackPages * globals.blackPrice  | number : '1.2-2'}}€</mat-grid-tile>
                            <mat-grid-tile *ngIf="!order.color" [colspan] = "2">{{order.quantity * (order.nbBlackPages +  order.nbColorPages)  }} </mat-grid-tile>
                            <mat-grid-tile *ngIf="!order.color" [colspan] = "2">{{order.quantity*(order.nbBlackPages + order.nbColorPages) * globals.blackPrice  | number : '1.2-2'}}€</mat-grid-tile>
                            <mat-grid-tile [colspan] = "6">Feuilles en Couleurs:  </mat-grid-tile>
                            <mat-grid-tile *ngIf="order.color" [colspan] = "2">{{order.quantity * order.nbColorPages }} </mat-grid-tile>
                            <mat-grid-tile *ngIf="order.color" [colspan] = "2">{{order.quantity * order.nbColorPages * globals.colorPrice | number : '1.2-2'}}€</mat-grid-tile>
                            <mat-grid-tile *ngIf="!order.color" [colspan] = "2">0</mat-grid-tile>
                            <mat-grid-tile *ngIf="!order.color" [colspan] = "2">0€</mat-grid-tile>
                            <mat-grid-tile [colspan] = "6">Couverture:  </mat-grid-tile>
                            <mat-grid-tile *ngIf="order.couverture.id == 'aucun'"  [colspan] = "2">0</mat-grid-tile>
                            <mat-grid-tile *ngIf="order.couverture.id != 'aucun'"  [colspan] = "2">{{order.quantity}} </mat-grid-tile>
                            <mat-grid-tile [colspan] = "2">{{order.quantity * order.couverture.price | number : '1.2-2'}}€</mat-grid-tile>
                            <mat-grid-tile [colspan] = "6">Finition:  </mat-grid-tile>
                            <mat-grid-tile *ngIf="order.finition.id == 'aucun'" [colspan] = "2">0</mat-grid-tile>
                            <mat-grid-tile *ngIf="order.finition.id != 'aucun'" [colspan] = "2">{{order.quantity }} </mat-grid-tile>
                            <mat-grid-tile [colspan] = "2">{{order.quantity  * order.finition.price | number : '1.2-2'}}€</mat-grid-tile>
                            <mat-divider></mat-divider>
                            <mat-grid-tile [colspan] = "5"></mat-grid-tile>
                            <mat-grid-tile [colspan] = "3" [style.border-top]="'1px solid #ddd'" [style.font-size]="'14px'"><div class="text-align-grid"><b>Total TTC: </b></div></mat-grid-tile>
                            <mat-grid-tile [colspan] = "2" [style.border-top]="'1px solid #ddd'" [style.font-size]="'14px'"><b>{{order.getTotal() | number : '1.2-2'}}€</b></mat-grid-tile>

                        </mat-grid-list>

                        <div *ngIf="order.nbBlackPages < 0 || order.nbColorPages < 0 || order.nbErrorPages > 0" cols="10" rowHeight="14px" style="font-size: 13px;" class="page-header--centered">
                            <div class="success-message">Un problème est survenu lors du tratement du fichier, veuillez réessayer plus tard ou essayer un autre fichier. </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="transfer__footer">
                <button style="left: 25% !important; width: 8.75em !important; margin-right: 0.05em !important;" type="button" [disabled]="!fileAdded || uploading || !uploadSuccessful || order.quantity <=0 || order.getTotal() <=0 ||  !fileInfoTreated ||  !treated ||  !urlTreated || order.nbBlackPages <0 || order.nbColorPages <0 || order.nbErrorPages >0" [ngClass]="{'transfer__button--inactive' : !fileAdded || uploading || !uploadSuccessful  || order.quantity <=0 || order.getTotal() <=0 ||  !fileInfoTreated ||  !treated ||  !urlTreated || order.nbBlackPages <0 || order.nbColorPages <0 || order.nbErrorPages >0}" class="transfer__button" (click)="addToCart()">Ajouter au panier</button> &nbsp;&nbsp;
                
                <button style="left: 25% !important; width: 6.75em !important;"type="button" [disabled]="cart.orders.length <= 0 && ( !fileAdded || uploading || !uploadSuccessful || order.quantity <=0 || order.getTotal() <=0 ||  !fileInfoTreated ||  !treated ||  !urlTreated || order.nbBlackPages <0 || order.nbColorPages <0 || order.nbErrorPages >0)" [ngClass]="{'transfer__button--inactive' : cart.orders.length <= 0 && ( !fileAdded || uploading || !uploadSuccessful  || order.quantity <=0 || order.getTotal() <=0 ||  !fileInfoTreated ||  !treated ||  !urlTreated || order.nbBlackPages <0 || order.nbColorPages <0 || order.nbErrorPages >0)}" class="transfer__button" (click)="goToCart()">Payer</button>
                <!--button type="button" class="transfer__button" (click)="goToCart()">Payer</button-->
            </div>
        </div>
    </div>
</div>



<!!!---------------------Step Cart Validation ---------------------------->

<div *ngIf="step === 'cart'" ngClass.gt-xs="transfer transfer-desktop" ngClass.lt-sm="panel panel--full panel--no-transition panel--visible"  fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="space-evenly stretch" >
    <div ngClass.gt-xs="transfer__window uploader uploader--form uploader--type-email" ngClass.lt-sm="panel__content transfer-mobile">
        <div ngClass.lt-sm="transfer" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="space-evenly stretch" >
            <div ngClass.lt-sm="scrollable transfer__contents">
                <div ngClass.lt-sm="scrollable__content" style="margin-right: 0px; margin-top: 30px;
                                                        padding-left: 10px;
                                                        padding-right: 10px;">
                    <h2 style="text-align: left; padding-left: 0px;">Votre Panier</h2><br>

                    <div>
                        <div>
                            <div >
                                <mat-dialog-content fxFlex>
                                    <mat-grid-list cols="10" rowHeight="22px" style="font-size: 13px;">
                                        <mat-grid-tile [colspan] = "4"><b>Nom du Fichier</b></mat-grid-tile>
                                        <mat-grid-tile [colspan] = "2"><b>Quantité: </b></mat-grid-tile>
                                        <mat-grid-tile [colspan] = "2"><b>Prix: </b></mat-grid-tile>
                                        <mat-grid-tile [colspan] = "2"><b>Supprimer: </b></mat-grid-tile>
                                        <ng-container *ngFor="let order of cart.orders">
                                            <mat-grid-tile [colspan] = "4">
                                                {{ order.file}}
                                            </mat-grid-tile>
                                            <mat-grid-tile [colspan] = "2">
                                                {{ order.quantity}}
                                            </mat-grid-tile>
                                            <mat-grid-tile [colspan] = "2">
                                                {{ order.getTotal() | number : '1.2-2'}}€
                                            </mat-grid-tile>
                                            <mat-grid-tile [colspan] = "2">
                                                <mat-icon aria-hidden="true" (click)="deleteItem(order.id)">delete_forever</mat-icon>
                                            </mat-grid-tile>
                                        </ng-container>
                                         <mat-divider></mat-divider>
                                         
                                        
                                         <mat-grid-tile [colspan] = "6" [style.border-top]="'1px solid #ddd'" [style.font-size]="'14px'"><div class="text-align-grid"><b>Impression : </b></div></mat-grid-tile>
                                         <mat-grid-tile [colspan] = "2" [style.border-top]="'1px solid #ddd'" [style.font-size]="'14px'"><b>{{cart.getTotal()  | number : '1.2-2'}}€</b></mat-grid-tile>
                                         
                                         <mat-grid-tile [colspan] = "2"></mat-grid-tile>
                                        <mat-divider></mat-divider>
                                        
                                        <div *ngIf="cart.getTotal() < 2 ">
                                        <mat-grid-tile [colspan] = "6" [style.font-size]="'14px'"><div class="text-align-grid"><b>Frais d'ouverture de fichier : </b></div></mat-grid-tile>
                                         <mat-grid-tile [colspan] = "2" [style.font-size]="'14px'"><b>1.5€</b></mat-grid-tile>
                                         
                                         <mat-grid-tile [colspan] = "2"></mat-grid-tile>
                                       <mat-divider></mat-divider>
                                        </div>
                                        
                                        
                                         <mat-grid-tile [colspan] = "6" [style.font-size]="'14px'"><div class="text-align-grid"><mat-checkbox [disabled]=false [(ngModel)]="cart.shipping" [checked]="cart.shipping"><b *ngIf="cart.shipping" >Livraison: </b><b *ngIf="!cart.shipping" >Retrait en magasin: </b></mat-checkbox></div></mat-grid-tile>
                                         <mat-grid-tile [colspan] = "2" [style.font-size]="'14px'"><b>{{cart.getShippingCost()}}€</b></mat-grid-tile>
                                         <mat-grid-tile [colspan] = "2"></mat-grid-tile>
                                         <mat-divider></mat-divider>
                                        
                                         <mat-grid-tile [colspan] = "6" [style.border-top]="'1px solid #ddd'" [style.font-size]="'14px'"><div class="text-align-grid"><b>Total TTC: </b></div></mat-grid-tile>
                                         <mat-grid-tile [colspan] = "2" [style.border-top]="'1px solid #ddd'" [style.font-size]="'14px'"><b>{{cart.getTotalWithShipping() | number : '1.2-2'}}€</b></mat-grid-tile>

                                         <mat-grid-tile [colspan] = "2"></mat-grid-tile>
                                         <mat-divider></mat-divider>
                                                    
                                        
                                    </mat-grid-list>
                                </mat-dialog-content>
                            </div>
                        </div>
                    </div>
                    <br/><br/>
                        <button (click)="backHome()" lass="my-button">Retour</button>&nbsp;&nbsp;&nbsp;&nbsp; <button class="my-button" (click)="step = 'information'" [disabled]="cart.orders <= 0">Suivant</button>
                    <!--button (click)="backHome()" lass="my-button">Retour</button>&nbsp;&nbsp;&nbsp;&nbsp; <button class="my-button" (click)="step = 'information'">Suivant</button-->
                </div>
            </div>
        </div>
    </div>
</div>




<!!!---------------------Step Information ---------------------------->


<div *ngIf="step === 'information'" ngClass.gt-xs="transfer transfer-desktop" ngClass.lt-sm="panel panel--full panel--no-transition panel--visible"  fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="space-evenly stretch" >
    <div ngClass.gt-xs="transfer__window uploader uploader--form uploader--type-email" ngClass.lt-sm="panel__content transfer-mobile">
        <div ngClass.lt-sm="transfer" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="space-evenly stretch" >
            <div ngClass.lt-sm="scrollable transfer__contents">
                <div ngClass.lt-sm="scrollable__content" style="margin-right: 0px; margin-top: 30px;
                                                        padding-left: 10px;
                                                        padding-right: 10px;">
                    <h2 style="text-align: left; padding-left: 0px;">Informations de contact:</h2><br>

                    <div  >
                        <div>
                            <div >
                                <mat-dialog-content fxFlex>
                                    <mat-list>

                                        <div fxLayout="row" fxLayoutGap="60px" fxLayoutAlign="start center" style="margin-top: 10px;">
                                            <mat-form-field>
                                                <input required  matInput #message maxlength="80" placeholder="Nom" [(ngModel)]="cart.name">
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="row" fxLayoutGap="60px" fxLayoutAlign="start center" style="margin-top: 10px;">
                                            <mat-form-field>
                                                <input required matInput #message maxlength="80" placeholder="Prénom" [(ngModel)]="cart.firstName">
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="row" fxLayoutGap="60px" fxLayoutAlign="start center" style="margin-top: 10px;">
                                            <mat-form-field>
                                                <input required matInput maxlength="130" placeholder="Rue et numéro" [(ngModel)]="cart.street">
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="row" fxLayoutGap="60px" fxLayoutAlign="start center" style="margin-top: 10px;">
                                            <div class="twentyPercent">
                                                <mat-form-field>
                                                    <input required matInput maxlength="8" placeholder="Code Postal" [(ngModel)]="cart.postCode">
                                                </mat-form-field></div> &nbsp;&nbsp;&nbsp;<div class="heightyPercent">
                                                <mat-form-field>
                                                    <input required matInput maxlength="80" placeholder="Commune" [(ngModel)]="cart.city">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div fxLayout="row" fxLayoutGap="60px" fxLayoutAlign="start center" style="margin-top: 10px;">
                                            
                                            <mat-form-field>
                                                <input required matInput #message maxlength="150" placeholder="Email" [formControl]="emailFormControl" [errorStateMatcher]="matcher" [(ngModel)]="cart.email" >
                                                <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                                    Merci d'entrer un email valide
                                                </mat-error>
                                                <mat-error *ngIf="emailFormControl.hasError('required')">
                                                    Email est <strong>obligatoire</strong>
                                                </mat-error>
                                            </mat-form-field>
                                            
                                        </div>
                                        <div fxLayout="row" fxLayoutGap="60px" fxLayoutAlign="start center" style="margin-top: 10px;">
                                            <mat-form-field>
                                                <input matInput #message maxlength="20" placeholder="Téléphone" [(ngModel)]="cart.phone">
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="cart.shipping" fxLayout="row" fxLayoutGap="60px" fxLayoutAlign="start center" style="margin-top: 15px;">
                                            <mat-checkbox [(ngModel)]="cart.contactAsShipping" [checked]="cart.contactAsShipping">Utiliser comme adresse de livraison</mat-checkbox>
                                        </div>
                                        <br><br><br><br>
                                        
                                         
                                    </mat-list>
                                </mat-dialog-content>
                            </div>
                        </div>
                    </div>

                    <button (click)="step = 'cart'" class="my-button">Retour</button>&nbsp;&nbsp;&nbsp;&nbsp; <button class="my-button" (click)="stepAfterContactForm()" [disabled]="!cart.name || !cart.firstName || !cart.street || !cart.postCode || !cart.city || !cart.email || emailFormControl.hasError('email')">Suivant</button>
                    <!--button (click)="step = 'cart'" class="my-button">Retour</button>&nbsp;&nbsp;&nbsp;&nbsp; <button class="my-button" (click)="stepAfterContactForm()">Suivant</button-->

                </div>
            </div>
        </div>
    </div>
</div>

<!!!---------------------Step shippingAdress ---------------------------->

<div *ngIf="step === 'shippingAdress'" ngClass.gt-xs="transfer transfer-desktop" ngClass.lt-sm="panel panel--full panel--no-transition panel--visible"  fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="space-evenly stretch" >
    <div ngClass.gt-xs="transfer__window uploader uploader--form uploader--type-email" ngClass.lt-sm="panel__content transfer-mobile">
        <div ngClass.lt-sm="transfer" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="space-evenly stretch" >
            <div ngClass.lt-sm="scrollable transfer__contents">
                <div ngClass.lt-sm="scrollable__content" style="margin-right: 0px; margin-top: 30px;
                                                        padding-left: 10px;
                                                        padding-right: 10px;">
                    <h2 style="text-align: left; padding-left: 0px;">Adresse de livraison:</h2><br>

                    <div  >
                        <div>
                            <div >
                                <mat-dialog-content fxFlex>
                                    <mat-list>

                                        <div fxLayout="row" fxLayoutGap="60px" fxLayoutAlign="start center" style="margin-top: 10px;">
                                            <mat-form-field>
                                                <input required  matInput #message maxlength="80" placeholder="Nom" [(ngModel)]="cart.shippingName">
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="row" fxLayoutGap="60px" fxLayoutAlign="start center" style="margin-top: 10px;">
                                            <mat-form-field>
                                                <input required matInput #message maxlength="80" placeholder="Prénom" [(ngModel)]="cart.shippingFirstName">
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="row" fxLayoutGap="60px" fxLayoutAlign="start center" style="margin-top: 10px;">
                                            <mat-form-field>
                                                <input required matInput #message maxlength="130" placeholder="Rue et numéro" [(ngModel)]="cart.shippingStreet">
                                            </mat-form-field>
                                        </div> 
                                        <div fxLayout="row" fxLayoutGap="60px" fxLayoutAlign="start center" style="margin-top: 10px;">
                                            <div class="twentyPercent">
                                                <mat-form-field>
                                                    <input required matInput maxlength="8" placeholder="Code Postal" [(ngModel)]="cart.shippingPostCode">
                                                </mat-form-field></div> &nbsp;&nbsp;&nbsp;<div class="heightyPercent">
                                                <mat-form-field>
                                                    <input required matInput maxlength="80" placeholder="Commune" [(ngModel)]="cart.shippingCity">
                                                </mat-form-field>
                                            </div>
                                        </div>  
                                    </mat-list>
                                </mat-dialog-content>
                            </div>
                        </div>
                    </div>

                    <button (click)="step = 'information'" class="my-button">Retour</button>&nbsp;&nbsp;&nbsp;&nbsp; <button class="my-button" (click)="goToPayment()" [disabled]="!cart.shippingName || !cart.shippingFirstName || !cart.shippingStreet || !cart.shippingPostCode || !cart.shippingCity">Suivant</button>
                    <!--button (click)="step = 'information'" class="my-button">Retour</button>&nbsp;&nbsp;&nbsp;&nbsp; <button class="my-button" (click)="goToPayment()">Suivant</button-->

                </div>
            </div>
        </div>
    </div>
</div>




<!!!---------------------Step payment ---------------------------->

<div *ngIf="step === 'payment'" ngClass.gt-xs="transfer transfer-desktop" ngClass.lt-sm="panel panel--full panel--no-transition panel--visible"  fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="space-evenly stretch" >
    <div ngClass.gt-xs="transfer__window uploader uploader--form uploader--type-email" ngClass.lt-sm="panel__content transfer-mobile">
        <div ngClass.lt-sm="transfer" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="space-evenly stretch" >
            <div ngClass.lt-sm="scrollable transfer__contents">
                <div ngClass.lt-sm="scrollable__content" style="margin-right: 0px; padding-top: 30px;
                                                        padding-left: 10px;
                                                        padding-right: 10px;">
                    <ngx-braintree 
                                   [clientTokenURL]="clientTokenUrl"
                                   [createPurchaseURL]="createPurchaseURL" 
                                   [chargeAmount]="cart.getTotalWithShipping()"
                                   (paymentStatus)="onPaymentStatus($event)"
                                   [showCardholderName]="true"
                                   [enablePaypalCheckout] = "true"
                                   [currency]="'EUR'"
                                   [locale]="'fr_FR'">

                        <style>
                            button {
                                background: #536dfe;
                                border: 1px solid #536dfe;
                                border-radius: 12px;
                                box-sizing: border-box;
                                color: #ffffff;
                                cursor: pointer;
                                font-size: 1em;
                                font-weight: 500;
                                font-family: -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
                                height: 40px;
                                line-height: 40px;
                                cursor: pointer;
                            }

                            button:disabled {
                                background-color: #babcbf;
                                background: #babcbf;

                                border-radius: 12px;
                                box-sizing: border-box;
                                color: #ffffff;
                                cursor: pointer;
                                font-size: 1em;
                                font-weight: 500;
                                font-family: -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
                                height: 40px;
                                line-height: 40px;
                                border: none;
                                cursor: not-allowed;
                            }
                        </style>


                        <div class="ngxButtons">
                            <div>&nbsp;&nbsp;&nbsp;<button (click)="backToInfo()">Retour</button>&nbsp;&nbsp;&nbsp;&nbsp; <button ngxPay>Payer</button></div>
                        </div>

                    </ngx-braintree>

                    <div *ngIf="paymentError" class="page-header--centered">
                        <mat-icon aria-hidden="false" class="error-icon">error_outiline</mat-icon> <br><br><br>
                        <div class="success-message">Un problème est survenu lors du paiment de votre commande, le paiement n'a pu être effectué. </div>
                    </div>
                    <div *ngIf="paymentSucceed" class="page-header--centered">
                        <mat-icon aria-hidden="false" aria-label="Example home icon" class="succeed-icon">check_circle_outline</mat-icon>  <br><br><br><div class="success-message">Nous vous remercions pour votre commande!<br>Votre numéro de commande : {{transactionID}}</div>
                        <button class="transfer__button" (click)="backHomeAndReset()" style="top: 88% !important; position: absolute !important">Retour</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>








